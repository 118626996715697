interface Props {
  photo: string;
}

export const Thumbnail = ({ photo }: Props) => {
  const photoBaseEndpoint = "https://files.samraam.com/get/samraam/";
  let photoURL = "";
  if (!photo.includes(photoBaseEndpoint)) {
    photoURL = photoBaseEndpoint + photo;
  } else {
    photoURL = photo;
  }

  return (
    <div className="max-w-lg">
      <a href={`${photoURL}`} target="_blank">
        <img
          src={`${photoURL}?dim=1024`}
          style={{ height: 200 }}
          alt="Property"
        />
      </a>
    </div>
  );
};
