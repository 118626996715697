import { Spinner } from "@components/Spinner";
import useProperty from "@hooks/useProperty";
import { useLocation } from "react-router-dom";
import { PropertyBillings, PropertyDetail } from "./components";

const Properties = () => {
  const location = useLocation();

  const [, queryString = ""] = location.search.split("?");

  const queryObj = queryString
    .split("&")
    .reduce<Map<string, string>>((prev: any, curr: any) => {
      const [key, value] = curr.split("=");
      prev.set(key, value);
      return prev;
    }, new Map());

  const { data, isLoading, isError } = useProperty(queryObj.get("code"));
  // prettier-ignore
  return (
    <div className="prose m-auto max-w-full flex-col items-center justify-center bg-[#f6f6f7] pb-5">
      <div className="flex flex-col items-center py-2">
        <img
          src="/logo.svg"
          alt="Samraam QR Property Detail Logo"
          className="w-20 h-20"
        />
      </div>
      {isLoading && (
        <div className="flex items-center justify-center h-screen">
          <Spinner />
        </div>
      )}
      {isError && (
        <div className="flex items-center justify-center h-screen">
          An error occur
        </div>
      )}
      {data && (
        <>
          <PropertyDetail detail={data?.property} />
          <PropertyBillings billings={data?.billings} property={data?.property} />
        </>
      )}
    </div>
  );
};

export default Properties;
